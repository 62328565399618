import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../layout"
import PageTitle from "../generic/page-title"
import SEO from "../seo"

const BlogPostTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.excerpt} />
      <PageTitle title={frontmatter.title} />
      <section className="blog-post">
        <div className="blog-post__content">
          <div className="blog-post__date">
            Posted on {frontmatter.date} by <em>{frontmatter.author.name}</em>
          </div>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <div className="blog-post__about-author">
            <div className="blog-post__about-author-name">
              <h2 className="heading-secondary blog-post__about-author-name--heading-secondary">
                About the author: <em>{frontmatter.author.name}</em>
              </h2>
            </div>
            <p className="paragraph">{frontmatter.author.bio}</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPostTemplate

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        author {
          id
          name
          bio
          homepage
        }
      }
    }
  }
`
