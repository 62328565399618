import React from "react"
import PropTypes from "prop-types"

const PageTitle = ({ title }) => {
  return (
    <section className="page-title">
      <h1 className="heading-primary">{title}</h1>
    </section>
  )
}

export default PageTitle

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}
